import { useEffect, useRef, useState } from 'react';
import { isMobile } from "react-device-detect";
import { AUDIO_SOURCES } from '../constants';

const useAudioEffects = () => {
    const [speakerList, setSpeakerList] = useState([]);
    const [selectedSpeaker, setSelectedSpeaker] = useState("default");
    const [speakerVolume, setSpeakerVolume] = useState(0.5);
    const [resumeModal, setResumeModal] = useState(true);
    const [audioCtxState, setAudioCtxState] = useState(true);

    const lobbySoundRef = useRef(new Audio());
    const jamEndSoundRef = useRef(new Audio());
    const countDownRef = useRef(new Audio());
    const winnerSoundRef = useRef(new Audio());
    const somebodyCalledJindo = useRef(new Audio());
    const verifying = useRef(new Audio());

    useEffect(() => {
        lobbySoundRef.current.loop = true;
        jamEndSoundRef.current.loop = true;

        // Set audio sources
        lobbySoundRef.current.src = `${process.env.SOUND_EFFECTS_URL}${AUDIO_SOURCES.lobbySoundRef}`;
        jamEndSoundRef.current.src = `${process.env.SOUND_EFFECTS_URL}${AUDIO_SOURCES.jamEndSoundRef}`;
        countDownRef.current.src = `${process.env.SOUND_EFFECTS_URL}${AUDIO_SOURCES.countDownRef}`;
        somebodyCalledJindo.current.src = `${process.env.SOUND_EFFECTS_URL}${AUDIO_SOURCES.somebodyCalledJindo}`;
        verifying.current.src = `${process.env.SOUND_EFFECTS_URL}${AUDIO_SOURCES.verifying}`;
        winnerSoundRef.current.src = `${process.env.SOUND_EFFECTS_URL}${AUDIO_SOURCES.winnerSoundRef}`;

        if (!isMobile) {
            lobbySoundRef.current.play().catch(() => setResumeModal(true));
        }
    }, []);

    useEffect(() => {
        (async () => {
            await navigator?.mediaDevices
                ?.enumerateDevices()
                ?.then((devices) => {
                    const speakers = devices.filter(
                        (device) => device.kind === "audiooutput" && device.label !== ""
                    );
                    setSpeakerList(speakers);
                });
        })();
        return () => {
            setSpeakerList([])
        }
    }, []);

    useEffect(() => {
        if (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime) && !isMobile) {
            lobbySoundRef.current.setSinkId(selectedSpeaker);
            jamEndSoundRef.current.setSinkId(selectedSpeaker);
        }
    }, [selectedSpeaker]);

    useEffect(() => {
        lobbySoundRef.current.volume = speakerVolume * 0.2;
        jamEndSoundRef.current.volume = speakerVolume * 0.2;
    }, [speakerVolume]);

    const handleCloseResumeModal = () => {
        setResumeModal(false);
        if (audioCtxState) {
            // Initialize all audio contexts
            [countDownRef, somebodyCalledJindo, verifying, winnerSoundRef].forEach(ref => {
                ref.current.play();
                ref.current.pause();
            });

            lobbySoundRef.current.pause();
            lobbySoundRef.current.play();
            lobbySoundRef.current.volume = 0.25;

            setAudioCtxState(false);
        }
    };

    return {
        lobbySoundRef,
        jamEndSoundRef,
        countDownRef,
        winnerSoundRef,
        somebodyCalledJindo,
        verifying,
        speakerList,
        selectedSpeaker,
        speakerVolume,
        resumeModal,
        audioCtxState,
        handleCloseResumeModal,
        setSpeakerVolume,
        setSelectedSpeaker,
        setResumeModal,
        setSpeakerList
    };
};

export default useAudioEffects; 