import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Row, Col } from "antd";
import * as Sentry from "@sentry/react";
import { BigRedSplashIcon } from "../../../utils/Icons";
import delay from '../../../utils/delay';
import GamePlay from "../../../components/player/gamePlay/GamePlay";

import CountDownModal from "../../../components/modals/CountDownModal";
import GameEventsModal from "../../../components/modals/GameEventsModal";
import GameWinnerFormModal from "../../../components/modals/GameWinnerFormModal";
import GameQuitModal from "../../../components/modals/GameQuitModal";
import GameService from "../../../services/GameService";

const GamePlayContainer = ({ 
    jam, 
    activeGame,
    gameWinner,
    checkForWinner, 
    onWinnerSubmission, 
    player, 
    leaveGame, 
    updateCardLayout, 
    updateCDModalState,
    updateCallJindoStatus
}) => {
    const playerData = JSON.parse(localStorage.getItem("player-data"));

    const [splashWinnerName, setSplashWinnerName] = useState("");
    const [splashMessage, setSplashMessage] = useState("10");
    const [splashMessageHeader, setSplashMessageHeader] = useState("");
    const [splashMessageSub, setSplashMessageSub] = useState("");
    const [splashMessageHelper, setSplashMessageHelper] = useState("");

    const [songsList, setSongsList] = useState([]);

    const [gameEventsModal, setGameEventsModal] = useState(false);
    const [gameQuitModal, setGameQuitModal] = useState(false);

    const [isCallJindoDisabled, setIsCallJindoDisabled] = useState(true);
    const [winnerFormHide, setWinnerFormHide] = useState(true);
    const [claimPrizeLoader, setClaimPrizeLoader] = useState(false);
    const [verifying, setVerifying] = useState(false);

    const winnerFormRef = useRef(null);

    const callJindoSound = useRef(
        new Audio(`${process.env.SOUND_EFFECTS_URL}J-J-J-J-Jindo!.mp3`)
    ).current;
    const ohNoSound = useRef(
        new Audio(`${process.env.SOUND_EFFECTS_URL}OhNo.mp3`)
    ).current;
    const gameOnSound = useRef(
        new Audio(`${process.env.SOUND_EFFECTS_URL}gameOn.mp3`)
    ).current;

    //
    //
    //
    // LIFE CYCLE METHODS
    //
    //
    //

    useEffect(() => {
        if (songsList.length) {
            updateCardLayout(songsList)
        }
    }, [songsList])

    useEffect(() => {
        resetModalText();
    }, []);

    useEffect(() => {
        if (splashMessage === "GAME ON!") {
            document.addEventListener("click", closeSplashModal);
        }
    }, [splashMessage]);

    useLayoutEffect(() => {
        if (player?.hasOwnProperty("cardLayout")) {
            setSongsList(player.cardLayout);
        }

        if (player?.callJindoStatus === 'TRY_AGAIN') {
            tryAgain();
        }
    }, [player])
    
    useEffect(() => {
        if(gameWinner?.isFinished) {
            if(!gameWinner?.name) {
                setGameEventsModal(true);
                try {
                    ohNoSound.remove();
                    ohNoSound.play();
                } catch (e) {
                    Sentry.captureException(e)
                }
                setSplashMessage("GAME OVER");
                setSplashMessageSub("");
            }
            else if (gameWinner?.winnerId === player?.id) {
                playerIsWinner();
            }
            else if (gameWinner.winnerId !== player?.id) {
                setGameEventsModal(true);
                setSplashWinnerName(gameWinner?.name)
                setSplashMessage(`IS THE WINNER!`);
                setSplashMessageSub("");
            }
        }
        
    }, [gameWinner]);

    useEffect(() => {
        const isAvailable = checkForCallJindo(songsList);
        setIsCallJindoDisabled(!isAvailable);
    }, [songsList]);

    //
    //  GAME LOGIC
    //

    const resetModalText = () => {
        setGameEventsModal(false);
        setSplashWinnerName("");
        setSplashMessage("");
        setSplashMessageSub("");
    }

    const handleTileSelect = (songId) => {
        const newSongsList = songsList.map((item) => {
            if (item.songId === songId) {
                return {
                    ...item,
                    selected: !item.selected,
                    countClicked: item.countClicked
                        ? item.countClicked + 1
                        : item.key + 1,
                };
            }
            return item;
        });
        setSongsList(newSongsList);
        // cardClicked(jamId, { ...playerInfo, cardLayout: newSongsList })
    };

    const onJindoCall = async () => {
        updateCallJindoStatus(player?.id, 'verify');
        setVerifying(true);
        setGameEventsModal(true);
        callJindoSound.remove();
        callJindoSound.play();
        setSplashMessage(`YOU \n\n\n CALLED JINDO!`);
        setSplashMessageHeader("");
        setSplashMessageSub("");
        setSplashMessageHelper("");
        
        setSplashMessageSub("VERIFYING...");
        checkForWinner(activeGame?.gameId, player);
        
        // const cardLayout = player?.cardLayout.map(({key, songId, selected}) => {
        //     return {key, songId, selected}
        // })
        // callJindo(`${activeGame?.gameId}<>${player?.id}<>${JSON.stringify(cardLayout)}`)
   };

   const playerIsWinner = async () => {
    console.log(gameWinner, player)
        if (gameWinner?.winnerId === player?.id) {
            onWinnerSubmission(true);
            setGameEventsModal(true);
            setWinnerFormHide(true);
            setSplashMessage("WINNER!!");
            setSplashMessageSub("");

            await delay(5000);

            setGameEventsModal(false);
            setWinnerFormHide(false);
            setVerifying(false);
        }
   }

    const tryAgain = () => {       
        if(gameWinner?.name) return;
        
        delay(3000).then(() => {
            setVerifying(false);
            setGameEventsModal(true);
            try {
                ohNoSound.remove();
                ohNoSound.play();
            } catch (e) {
                Sentry.captureException(e)
            }
            
            setSplashMessage("OH NO!");
            setSplashMessageSub("KEEP TRYING!");
        })
    
        delay(4000).then(() => {
            try {
                gameOnSound.remove();
                gameOnSound.play();
            } catch (e) {
                Sentry.captureException(e)
            }
        })            
    }

    const winnerFormSubmit = (values) => {
        setClaimPrizeLoader(true)
        GameService.gameWinner({
            ...values,
            playerId: player.id,
            playerName: player.name,
            jamId: jam?.jamId,
            gameNumber: activeGame.key + 1,
            prize: activeGame.prize,
        }).then(() => {
            setClaimPrizeLoader(false)
            handleWinnerFormlessNavigate();
        })
        .catch((err) => {
            setClaimPrizeLoader(false)
            console.log(err);
        });
    };

    const checkForCallJindo = (songs = []) => {
        let validatePattern = false;
        if (songs.length === 0) {
            return false;
        }
        try {
            const selectedKeys = songs
                .filter((song) => song?.selected)
                .map((song) => song?.key);
            for (const pattern of activeGame?.winningPattern?.pattern) {
                if (pattern?.every((key) => selectedKeys.includes(key))) {
                    validatePattern = true;
                }
            }
        } catch (e) {
            console.log(e)
        }
        
        return validatePattern;
    };

    const handleWinnerFormlessNavigate = () => {
        onWinnerSubmission(false);
        setWinnerFormHide(true);
        setGameEventsModal(true);
        setSplashMessage("WINNER!!");
        setSplashMessageSub("");
    };

    const closeSplashModal = () => {
        if (splashMessage === "GAME ON!") {
            setGameEventsModal(false);
            updateCallJindoStatus(player?.id, 'completed');
            document.removeEventListener("click", closeSplashModal);
        }
        setGameEventsModal(false);
        setGameQuitModal(false);
    };

    const onCallJamEnd = () => {
        setGameQuitModal(true);
        setSplashMessageSub("ARE YOU SURE YOU WANT TO QUIT PLAYING?");
        setSplashMessage("");
        setSplashMessageHeader("");
    };

    const handleQuitGame = () => {
        leaveGame();
        closeSplashModal();
        setGameQuitModal(false);
        window.location = `/postGame/#${jam?.host?.id}`;
        
    };

    return (
        <Row justify='center' align='top' className='game-container'>
            <GameEventsModal
                visible={gameEventsModal}
                onClose={null}
                splashWinnerName={splashWinnerName}
                splashMessage={splashMessage}
                splashMessageHeader={splashMessageHeader}
                splashMessageSub={splashMessageSub}
                splashMessageHelper={splashMessageHelper}
                onCloseModal={closeSplashModal}
                BigRedSplashIcon={BigRedSplashIcon}
            />
            {player?.cdModal && winnerFormHide && (
                <CountDownModal 
                    visible={player?.cdModal} 
                    updateCDModalState={() => {
                        updateCDModalState()
                    }} 
                    isHost={false} 
                />
            )}
            {activeGame && (
                <>
                <GameWinnerFormModal
                    visible={winnerFormHide}
                    winnerFormRef={winnerFormRef}
                    winnerFormSubmit={winnerFormSubmit}
                    handleWinnerFormlessNavigate={handleWinnerFormlessNavigate}
                    prize={activeGame?.prize}
                    BigRedSplashIcon={BigRedSplashIcon}
                    claimPrizeLoader={claimPrizeLoader}
                />
                <GameQuitModal
                    visible={gameQuitModal}
                    onCloseModal={closeSplashModal}
                    handleQuitGame={handleQuitGame}
                    BigRedSplashIcon={BigRedSplashIcon}
                />
                <Col span={24} style={{ height: "100%" }}>
                    {player && (
                        <GamePlay
                            splashMessage={splashMessage}
                            songsList={player.cardLayout}
                            handleTileSelect={handleTileSelect}
                            animate={player?.cardAnimate}
                            songsPlayed={activeGame.playedSongs.playedSongs.filter(song => song.show)}
                            onJindoCall={onJindoCall}
                            isCallJindoDisabled={isCallJindoDisabled}
                            onCallJamEnd={onCallJamEnd}
                            playerData={playerData}
                            game={activeGame}
                            jamTitle={jam?.title}
                        />
                    )}
                </Col>
                </>
            )}
        </Row>
    );
};

export default GamePlayContainer;
