import { useState, useEffect } from 'react';
import { TABS } from '../constants';

const useTabManagement = ({ jam, autoTabSwitch, setAutoTabSwitch }) => {
    const [activeTab, setActiveTab] = useState(TABS.JAM_DECK);
    const [jamCreateTab, setJamCreateTab] = useState(true);
    const [disableForCreateJamTab, setDisableForCreateJamTab] = useState(false);
    const [disableForJamStart, setDisableForJamStart] = useState(false);
    const [activeButton, setActiveButton] = useState("");

    useEffect(() => {
        if (jam?.hasOwnProperty("jamId") && autoTabSwitch) {
            if (jam.startingSoonGames && !jam.startedGame) {
                statingSoonGameTab();
                setAutoTabSwitch(false);
            }

            if (!jam.startingSoonGames && !jam.endedGame && !jam.startedGame) {
                setActiveTab(TABS.JAM_DECK);
                setJamCreateTab(true);
                setDisableForCreateJamTab(true);
                setActiveButton("saveJam");
                setDisableForJamStart(false);
            }

            if (jam.startedGame) {
                startedGameTab();
                setAutoTabSwitch(false);
            }
        }
    }, [jam, autoTabSwitch]);

    const handleTabChange = (value) => {
        setActiveTab(value);
        setJamCreateTab(value === TABS.JAM_DECK);
    };

    const startedGameTab = () => {
        setActiveTab(TABS.JAM_DECK);
        setJamCreateTab(true);
        setDisableForCreateJamTab(false);
        setActiveButton("songStreaming");
        setDisableForJamStart(false);
    };

    const statingSoonGameTab = () => {
        setActiveTab(TABS.INVITE);
        setJamCreateTab(false);
        setActiveButton("jamStart");
        setDisableForCreateJamTab(false);
        setDisableForJamStart(true);
    };

    return {
        activeTab,
        jamCreateTab,
        disableForCreateJamTab,
        disableForJamStart,
        activeButton,
        handleTabChange,
        statingSoonGameTab,
        startedGameTab,
        setActiveButton,
        setActiveTab
    };
};

export default useTabManagement; 