const { detect } = require("detect-browser");
const browser = detect();

class SongPlayService {
    constructor() {
        this.song_url = browser?.name === "safari" ? process.env.SONGS_SAFARI_URL : process.env.SONGS_URL;
        this.isPlaying = false;
    }

    setAudioSource(mp3Audio, songId) {
        try {
            mp3Audio.crossOrigin = "anonymous";
            mp3Audio.src = `${this.song_url}${songId}.mp3?now=${new Date().getTime()}`;
        } catch (e) {
            console.log(e, "-------")
        }
    }

    async playSong(mp3Audio, volume, counter = 0) {
        try {
            const audioPlayed = await mp3Audio.play();
            this.isPlaying = true;
            if (volume !== 0) {
                this.fadeIn(mp3Audio, volume === 1 ? 0.9 : volume);
            }
            return true;
        } catch (e) {
            return false;
        }
    }

    pauseSong(mp3Audio, forcedPause = false) {
        this.isPlaying = false;
        if (forcedPause) {
            return mp3Audio?.pause();
        }
        this.fadeOut(mp3Audio);
    }

    fadeIn(mp3Audio, volume) {
        if (mp3Audio.volume <= volume) {
            mp3Audio.volume += 0.1;
            setTimeout(() => this.fadeIn(mp3Audio, volume), 150);
        }
    }

    fadeOut(mp3Audio) {
        if (mp3Audio.volume > 0.2) {
            mp3Audio.volume -= 0.1;
            setTimeout(() => this.fadeOut(mp3Audio), 150);
        } else {
            mp3Audio.pause();
        }
    }

    setSongVolume(mp3Audio, volume) {
        mp3Audio.volume = volume;
    }
}

export default new SongPlayService();
