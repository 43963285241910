export const TABS = {
    JAM_DECK: "1",
    PLAYERS: "2",
    SONGS: "3",
    INVITE: "4",
    SETTINGS: "5"
};

export const AUDIO_SOURCES = {
    lobbySoundRef: "jindoLobbyNew.mp3",
    jamEndSoundRef: "gameEnd.mp3",
    countDownRef: "countdown.mp3",
    somebodyCalledJindo: "somebodyCalledJindo.mp3",
    verifying: "verifying.mp3",
    winnerSoundRef: "winner.mp3"
}; 