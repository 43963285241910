import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Typography, Space, Button } from "antd";

import {
    JamEndJindoLogo,
    PlayersBlackLogo,
    PrizeTransparentBlackLogo,
} from "../../../utils/Icons";

const { Text } = Typography;

const zeroPad = (num, places) => String(num).padStart(places, "0");

const JamEnd = ({ games, handleGoToDashboard, handleCreateNewJam }) => {
    const RenderRoundCard = (game, index) => {
        return (
            <Row key={index} className='winner-round-card-container'>
                <Col className='rd-text-col' span={2}>
                    <Space direction='vertical' size={0} align='center' justify='center' style={{minWidth: '46px'}}>
                        <Text className='rd-text'>RD</Text>
                        <Text className='rd-number-text'>
                            {zeroPad(index, 2)}
                        </Text>
                    </Space>
                </Col>
                <Col span={22} style={{ paddingLeft: 16 }}>
                    <Col
                        span={24}
                        style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }}
                    >
                        <PlayersBlackLogo
                            style={{
                                width: 19.36,
                                height: 15.23,
                                marginRight: 10,
                            }}
                        />
                        <Text className='game-over-winner-player-name'>
                            {game.winnerName ? game.winnerName : 'No Winner'}
                        </Text>
                    </Col>
                    <Col
                        span={24}
                        style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            marginTop: 10,
                        }}
                    >
                        <PrizeTransparentBlackLogo
                            style={{
                                width: 15.23,
                                height: 15.23,
                                marginRight: 14.13,
                            }}
                        />
                        <Text className='game-over-prize-text'>
                            {game.prize}
                        </Text>
                    </Col>
                </Col>
            </Row>
        );
    };

    return (
        <Row justify='center' style={{ width: "100%" }}>
            <Row style={{ position: "absolute", top: 0 }}>
                <JamEndJindoLogo style={{ width: 241, height: 129 }} />
            </Row>
            <Row justify='center' style={{ marginTop: 118 }}>
                <Space direction='vertical' size={9} align='center'>
                    <Text className='game-over-title'>GAME OVER</Text>
                    <Text className='game-over-sub-heading'>
                        and the Winners are...
                    </Text>
                </Space>
            </Row>
            <Col
                span={24}
                style={{
                    height: 440,
                    overflow: "scroll",
                    marginLeft: 36,
                    marginTop: 36,
                    overflowX: "hidden",
                }}
            >
                {games
                    .map((game, index) => RenderRoundCard(game, index + 1))}
            </Col>
            <Row 
                className={'jam-submit-btn-container'}
                style={{flexDirection: 'row', width: '100%'}}
            >
                <Col
                    span={12} 
                    style={{ marginTop: 38 }}
                >
                    <Button
                        block
                        className='jam-end-secondary-btn'
                        onClick={handleGoToDashboard}
                    >
                        <Row>
                            <Col span={24}>GO TO</Col>
                            <Col span={24}>DASHBOARD</Col>
                        </Row>
                    </Button>
                </Col>
                <Col span={12} style={{ marginTop: 38 }}>
                    <Button
                        block
                        className='jam-end-primary-btn'
                        onClick={handleCreateNewJam}
                    >
                        <Row>
                            <Col span={24}>CREATE</Col>
                            <Col span={24}>NEW JAM</Col>
                        </Row>
                    </Button>
                </Col>
            </Row>
        </Row>
    );
};

export default JamEnd;
